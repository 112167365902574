import React from 'react'
import PropTypes from 'prop-types'
// import { Location } from '@reach/router'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/header'
// import Hero from '../components/homesection/hero.js'
import Footer from '../components/footer'
import Bot from '../components/bot/bot'
import Sticky from 'react-sticky-el'
import get from 'lodash/get'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import './index.css'
import 'instantsearch.css/themes/algolia.css'
import { InstantSearch } from 'react-instantsearch-dom'
// import THBG from '../../static/img/testimonialbg.jpg'

class Layout extends React.Component {
  render() {
    const { head, children } = this.props

    // console.log(City)
    return (
      <StaticQuery
        render={data => {
          const siteTitle =
            get(head, 'title') || get(data, 'site.siteMetadata.title')
          const siteName =
            get(head, 'name') || get(data, 'site.siteMetadata.name')
          const siteMetaDescription =
            get(head, 'meta.description') ||
            get(data, 'site.siteMetadata.meta.description')
          const siteMetaKeywords =
            get(head, 'meta.keywords') ||
            get(data, 'site.siteMetadata.meta.keywords')

          return (
            <>
              <Helmet
                title={siteTitle + ' - ' + siteName}
                meta={[
                  {
                    name: 'description',
                    content: siteMetaDescription,
                  },
                  {
                    name: 'keywords',
                    content: siteMetaKeywords,
                  },
                  { property: 'og:locale', content: 'en_US' },
                  { property: 'og:url', content: 'https://www.brianboals.com' },
                  { property: 'og:type', content: 'website' },
                  { property: 'og:title', content: siteTitle },
                  { property: 'og:description', content: siteMetaDescription },
                  { property: 'og:site_name', content: siteName },
                  // Twitter
                  { property: 'twitter:card', content: 'summary' },
                  {
                    property: 'twitter:description',
                    content: siteMetaDescription,
                  },
                  { property: 'twitter:title', content: siteTitle },
                  {
                    name: 'google-site-verification',
                    content: `3PZhZoB0dVFwXEvLzFMedvkL8GFB4BwO0mlrLOzlYNA`,
                  },
                ]}
              >
                <html lang="en" />
              </Helmet>
              <header>
                <Sticky
                  className="sticky-wrapper"
                  stickyClassName="is-sticky"
                  stickyStyle={{ transform: 'unset', zIndex: '2' }}
                >
                  <Header />
                </Sticky>
              </header>
              <InstantSearch
                appId="5P6F9K09CU"
                apiKey="4b4079c67c9b1a68877edbd7d2e82cbc"
                indexName="BrianBoals"
                onSearchStateChange={this.props.onSearchStateChange}
              >
                {children}
              </InstantSearch>
              <Bot />
              <Footer />
            </>
          )
        }}
        query={graphql`
          query siteQuery {
            site {
              siteMetadata {
                title
                name
                meta {
                  keywords
                  description
                }
              }
            }
          }
        `}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
