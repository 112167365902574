import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'

const Bot = () => (
	<MessengerCustomerChat
		pageId="1730935360461477"
		appId="1678638095724206"
		minimized="false"
    shouldShowDialog="true"
		greetingDialogDisplay="show"
		greetingDialogDelay="5"
		htmlRef=""
	/>
)

export default Bot
